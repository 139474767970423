import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Loader Logo</title>
    {/* <g transform="translate(11.000000, 5.000000)"> */}
    {/* <path
          d=""
          fill="currentColor"
        /> */}

    <path
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M52 77.6V22.4l9.4-9.4c.8-.8.8-2.1 0-2.9-.8-.8-2.1-.8-2.9 0L52 16.6V7.5c0-1.1-.9-2-2-2s-2 .9-2 2v9.1l-6.5-6.5c-.8-.8-2.1-.8-2.9 0-.8.8-.8 2.1 0 2.9l9.4 9.4v55.2L38.6 87c-.8.8-.8 2.1 0 2.9.4.4.9.6 1.4.6s1-.2 1.4-.6l6.5-6.5v9.1c0 1.1.9 2 2 2s2-.9 2-2v-9.1l6.5 6.5c.4.4.9.6 1.4.6s1-.2 1.4-.6c.8-.8.8-2.1 0-2.9L52 77.6z"></path>
    <path
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M90.2 60c-.3-1.1-1.4-1.7-2.5-1.4L74.9 62 51 48.2 27.1 34.4l-3.4-12.8c-.3-1.1-1.4-1.7-2.5-1.4-1.1.3-1.7 1.4-1.4 2.5l2.4 8.8-8-4.5c-1-.6-2.2-.2-2.8.7-.6 1-.2 2.2.8 2.8l7.9 4.6-8.8 2.4c-1.1.3-1.7 1.4-1.4 2.5.2.9 1.1 1.5 2 1.5.2 0 .4 0 .5-.1L25.1 38 49 51.8l23.9 13.8 3.4 12.8c.2.9 1.1 1.5 2 1.5.2 0 .4 0 .5-.1 1.1-.3 1.7-1.4 1.4-2.5l-2.4-8.8 7.9 4.6c.3.2.7.3 1 .3.7 0 1.4-.4 1.8-1 .6-1 .2-2.2-.8-2.8L79.8 65l8.8-2.4c1.2-.4 1.9-1.5 1.6-2.6z"></path>
    <path
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M88.7 37.5l-8.8-2.4 7.9-4.6c1-.6 1.3-1.8.8-2.8-.6-1-1.8-1.3-2.8-.7l-7.9 4.6 2.4-8.8c.3-1.1-.4-2.2-1.4-2.5-1.1-.3-2.2.4-2.5 1.4L73 34.5 49 48.2 25.1 62l-12.8-3.4c-1.1-.3-2.2.4-2.5 1.4-.3 1.1.4 2.2 1.4 2.5l8.8 2.4-7.9 4.6c-1 .6-1.3 1.8-.8 2.8.4.7 1.1 1 1.8 1 .3 0 .7-.1 1-.3l7.9-4.6-2.4 8.8c-.3 1.1.4 2.2 1.4 2.5.2 0 .4.1.5.1.9 0 1.7-.6 2-1.5l3.4-12.8L51 51.8 74.9 38l12.8 3.4c.2 0 .4.1.5.1.9 0 1.7-.6 2-1.5.3-1.1-.4-2.2-1.5-2.5z"></path>
    <path
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M62.9 72.4L50 64.9l-12.9 7.4V57.4L24.2 50l12.9-7.5V27.6L50 35.1l12.9-7.4v14.9L75.8 50l-12.9 7.5v14.9zM50 60.2l8.8 5.1V55.1l8.8-5.1-8.8-5.1V34.7L50 39.8l-8.8-5.1v10.2L32.4 50l8.8 5.1v10.2l8.8-5.1z"></path>

    {/* <g> */}
    {/* <g id="B" transform="translate(11.000000, 5.000000)">
        <path
          d="M19.793 24.849L18.6287 27.2343L4.65718 0H6.98577L19.793 24.849ZM22.1216 20.0785L20.925 22.4638L9.31436 0H11.643L22.1216 20.0785ZM24.4502 15.3382L23.2859 17.7234L13.9715 0H16.3001L24.4502 15.3382ZM21.5071 33.1522L38.3571 0H40.6856L23.8357 33.1522V50H21.5071V33.1522ZM26.1643 33.1522L43.0142 0H45.3428L28.4929 33.1522V50H26.1643V33.1522ZM30.8215 33.1522L47.6714 0H50L33.1501 33.1522V50H30.8215V33.1522ZM18.0142 30.8575L33.6999 0H36.0285L19.1785 33.1522V50H16.8499V33.1522L0 0H2.32859L18.0142 30.8575 Z"
          fill="currentColor"
        />
      </g> */}

    {/* <path
      stroke="currentColor"
      strokeWidth="1"
      strokeLinecap="butt"
      strokeLinejoin="bevel"
      d="M19.793 24.849L18.6287 27.2343L4.65718 0H6.98577L19.793 24.849ZM22.1216 20.0785L20.925 22.4638L9.31436 0H11.643L22.1216 20.0785ZM24.4502 15.3382L23.2859 17.7234L13.9715 0H16.3001L24.4502 15.3382ZM21.5071 33.1522L38.3571 0H40.6856L23.8357 33.1522V50H21.5071V33.1522ZM26.1643 33.1522L43.0142 0H45.3428L28.4929 33.1522V50H26.1643V33.1522ZM30.8215 33.1522L47.6714 0H50L33.1501 33.1522V50H30.8215V33.1522ZM18.0142 30.8575L33.6999 0H36.0285L19.1785 33.1522V50H16.8499V33.1522L0 0H2.32859L18.0142 30.8575 Z"
    /> */}
  </svg>
);

export default IconLoader;
