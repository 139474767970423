import React, { useState, useEffect } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styled from 'styled-components';
import { email } from '@config';
import { Link } from 'gatsby';
import { navDelay, loaderDelay } from '@utils';
import { usePrefersReducedMotion } from '@hooks';
import SVG from '@components/svg';
import { UpDown, UpDownWide } from '@styles/animations';
const StyledHeroSection = styled.section`
  ${({ theme }) => theme.mixins.flexCenter};
  flex-direction: column;
  align-items: flex-start;
  min-height: 100vh;
  padding: 0;
  padding-top: 50px;
  @media (max-width: 480px) and (min-height: 700px) {
    padding-bottom: 10vh;
  }

  h1 {
    font-family: var(--font-mono);
    border-radius: 10px;
    z-index: 1000;
    backdrop-filter: blur(10px);
    margin: 40px 0 30px 4px;
    padding: 5px;
    color: var(--green);
    font-size: clamp(var(--fz-sm), 5vw, var(--fz-md));
    font-weight: 400;
    @media (max-width: 480px) {
      margin: 0 2px 20px 2px;
    }
  }
  h2 {
    text-shadow: 2px 2px 0 #a3adcc;
    backdrop-filter: blur(10px);
    z-index: 1000;
    padding: 5px;
    border-radius: 10px;
    margin-bottom: 5px;
    @media (max-width: 480px) {
      margin: 0 0 10px 15px;
      padding: 10px 5px 5px 5px;
    }
  }
  h3 {
    backdrop-filter: blur(10px);
    z-index: 1000;
    border-radius: 10px;
    color: var(--white);
    font-size: clamp(30px, 4vw, 50px);
    line-height: 0.9;
    padding: 20px 10px 20px 10px;
    @media (max-width: 480px) {
      font-size: 30px;
      margin: 0 10px 0 10px;
    }
  }

  p {
    margin: 20px 0 0;
    max-width: 500px;
    z-index: 1000;
    border-radius: 10px;
    backdrop-filter: blur(10px);
    font-size: clamp(20px, 4vw, 25px);
  }

  .email-link {
    ${({ theme }) => theme.mixins.bigButton};
    margin-top: 50px;
  }
  .wave {
    animation-name: wave-animation; /* Refers to the name of your @keyframes element below */
    animation-duration: 2.5s; /* Change to speed up or slow down */
    animation-iteration-count: infinite; /* Never stop waving :) */
    transform-origin: 70% 70%; /* Pivot around the bottom-left palm */
    display: inline-block;
  }

  @keyframes wave-animation {
    0% {
      transform: rotate(0deg);
    }
    10% {
      transform: rotate(14deg);
    } /* The following five values can be played with to make the waving more or less extreme */
    20% {
      transform: rotate(-8deg);
    }
    30% {
      transform: rotate(14deg);
    }
    40% {
      transform: rotate(-4deg);
    }
    50% {
      transform: rotate(10deg);
    }
    60% {
      transform: rotate(0deg);
    } /* Reset for the last half to pause */
    100% {
      transform: rotate(0deg);
    }
  }
`;
const Hero = () => {
  const [isMounted, setIsMounted] = useState(false);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    const timeout = setTimeout(() => setIsMounted(true), navDelay);
    return () => clearTimeout(timeout);
  }, []);

  const one = (
    <h1>
      Hi <span className="wave">👋</span>, my name is
    </h1>
  );
  const two = (
    <h2 style={{ color: 'white' }} className="big-heading">
      Yash Vadaria.{' '}
    </h2>
  );
  const three = <h3 padding="10px">I turn great ideas into responsive web/mobile application.</h3>;
  const four = (
    <p style={{ padding: '10px' }}>
      I'm a developer from India, who is devoted to crafting beautiful web experiences focused on
      simplicity and purpose.
      {/* Describing myself in one line &#128071;<br></br>
      Weeb with web development and Artistic skills <span fontSize="35px">&#128296;</span>. */}
      {/* Things I
      do the most &#128187; &#10145; &#127912; &#10145; &#127925; &#10145; &#127918; &#10145;
      &#127955;.Traditional and Ditigal Artist{' '} */}
      {/* <a href="https://www.instagram.com/yash_vadaria/" target="_blank" rel="noreferrer">
        @Art_by_Frost
      </a>
      . */}
    </p>
  );
  const five = (
    <Link className="email-link" to="/message">
      Get In Touch
    </Link>

    // <a href={`mailto:${email}`} className="email-link">
    //   Get In Touch
    // </a>
  );

  const items = [one, two, three, four, five];
  // reactIcon = reactIcon
  // arrowUp= mongoDB
  // flutter =flutter
  // cross="Pink box large center"
  // updown="procreate"
  //javascript
  //redux=""

  return (
    <StyledHeroSection>
      <UpDown>
        <SVG icon="procreate" width={16} color="icon_darkest" left="15%" top="65%" />
        <SVG icon="mongoDB" width={16} color="icon_blue" left="50%" top="90%" />
        <SVG icon="reactIcon2" width={16} color="icon_darkest" left="82%" top="86%" />
        <SVG icon="flutter" hiddenMobile width={56} color="icon_darkest" left="54%" top="20%" />
        <SVG icon="mongoDB" width={32} color="icon_green" left="45%" top="-30%" />
        <SVG icon="javascript" width={48} color="icon_darker" left="35%" top="44%" />
        <SVG icon="reactIcon2" width={32} color="icon_purple" left="4%" top="-20%" />
      </UpDown>
      <UpDownWide>
        <SVG icon="javascript" width={16} color="icon_darkest" left="70%" top="-20%" />
        <SVG icon="procreate" hiddenMobile width={16} color="icon_darkest" left="87%" top="30%" />
        <SVG icon="reactIcon2" width={8} stroke color="icon_darkest" left="30%" top="65%" />
        <SVG icon="redux" width={16} color="icon_darkest" left="72%" top="46%" />
        <SVG
          icon="redux"
          hiddenMobile
          width={32}
          stroke
          color="icon_darker"
          left="80%"
          top="-35%"
        />
        <SVG icon="procreate" hiddenMobile width={24} color="icon_darkest" left="13%" top="-50%" />

        {/* <SVG icon="cross" width={56} stroke color="icon_blue" left="18%" top="15%" /> */}
        {/* <SVG icon="flutter" hiddenMobile width={24} color="icon_darker" left="5%" top="70%" />
        <SVG icon="procreate" width={16} color="icon_darkest" left="58%" top="0%" />
        <SVG icon="redux" hiddenMobile width={48} color="icon_darker" left="25%" top="35%" /> */}
        <SVG icon="flutter" width={6} color="icon_darkest" left="4%" top="20%" />
        <SVG icon="redux" width={16} color="icon_darkest" left="15%" top="0%" />
        <SVG icon="reactIcon2" width={8} color="icon_darkest" left="35%" top="10%" />
        <SVG icon="mongoDB" width={16} color="icon_darker" left="10%" top="30%" />
        <SVG icon="javascript" width={8} color="icon_darker" left="80%" top="70%" />
      </UpDownWide>
      {prefersReducedMotion ? (
        <>
          {items.map((item, i) => (
            <div key={i}>{item}</div>
          ))}
        </>
      ) : (
        <TransitionGroup component={null}>
          {isMounted &&
            items.map((item, i) => (
              <CSSTransition key={i} classNames="fadeup" timeout={loaderDelay}>
                <div style={{ transitionDelay: `${i + 3}00ms` }}>{item}</div>
              </CSSTransition>
            ))}
        </TransitionGroup>
      )}
    </StyledHeroSection>
  );
};

export default Hero;
